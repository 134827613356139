.content{
  width: 100%;
  max-width: 800px;
}
.heading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading > span{
  margin-right: 1rem;
  font-size: 3rem;
}

.topRow {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.contButton {
  display: flex;
  /* justify-content: center; */
  margin: auto;
  padding:1.6rem;
  top:0.5rem;
}


@media (max-width: 500px) {
  .topRow {
    display: flex;
    flex-direction: column;
  }
}
.wqSection{
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.wqNav{
  display: flex;
  justify-content: center;
  width: 100%;

  --nav-font-size: 1.8rem;
}
.wqList{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  list-style-type: none;
}
.wqNavEl{
  margin: 0 1rem;
}
.wqNavLink{
  font-size: var(--nav-font-size);
  text-decoration: none;
}
.active{
  color: var(--secondary-color);
}
.newWqBtn{
  color: var(--btn-color) !important;
  font-size: var(--nav-font-size) !important;
}
.wqNav{
  display: flex;
  justify-content: center;
  width: 100%;

  --nav-font-size: 1.8rem;
}
.wqSection{
  display: flex;
  justify-content: center;
}
.bpList{
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
}
.bp{
  font-size: 2rem;
}
.bpText{
  width: 100%;
  text-wrap: wrap;
  text-overflow: wrap;
  border: none;
  outline: none;
  font-size: 2rem;
}
.bpInputWrapper{
  width: 80%;
  max-width: 500px;
  font-size: 2rem;
}
.bpInput{
  border: none;
  width: 100%;
  outline: none;
  font-size: 2rem;
}
.minutesField{
  flex-grow: 1;
}
.saveButtonWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: uppercase !important;
}
