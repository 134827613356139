#qnrs{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#qnrs-ul{
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

li::marker{
  display: none;
}

.heading{
  font-size: 3.5rem;
  text-align: center;
}
.pages-nav-cnt {
  display: flex;
  width: 90%;
}
.pages-nav-cnt .button-cnt{
  margin-left: auto;
  display: flex;
}
.pages-nav-cnt .button-cnt > button{
  margin: 0 .5rem;
  background-color: transparent;
  border: none;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  cursor: pointer;
}
.pages-nav-cnt .button-cnt > button.left{
  transform: rotate(180deg);
}
.pages-nav-cnt .button-cnt > button.left:hover{
  transform: rotate(180deg) scale(1.3);
}
.pages-nav-cnt .button-cnt > button.right:hover{
  transform: scale(1.3);
}

.pages-nav-cnt .info-cnt{
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
}
