.wq{
  width: 100%;
  display: flex;
}
.wqActionButtons{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5px;
  row-gap: 1px;
  font-size: 1.2rem;
  column-gap: 5px;
}
.wqActionButtons > button{
  padding: 0 !important;
  min-width: 10px;
}
.focused{
  background-color: rgba(48, 97, 255, .3) !important;
}

.wq:hover{
  animation: onwqhover .5s ;
  background-color: rgba(127, 127, 127, .3);
  cursor: pointer;
}
@keyframes onwqhover {
  0% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  100% {
    background-color: rgba(127, 127, 127, 0.3)
  }
}
