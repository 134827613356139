
#auth-btn-pretext{
  font-size: 2.2rem;
  margin-top: 2rem;
}

#auth-btn{
  background-color: var(--blue-btn-color);
  font-size: 2.5rem;

  padding: .5rem 2rem;
  margin: 2rem 0 1rem 0;

  border: none;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  
  cursor: pointer;
  transition: all .2s ease;
}

#auth-btn:hover{
  background-color: var(--blue-btn-color-hover);
}

#auth-btn:active{
  box-shadow: none;
  transform: translate(2px, 2px);
}

#auth-btn-aftertext{
  margin-bottom: auto;
}

.text{
  text-align: center;
  font-size: 1.2rem;
}

.text.default{
  color: black;
}

.text.contrast, .text.dark{
  color: white;
}
