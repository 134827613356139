.popupContainer{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;
  z-index: 999;

  justify-content: center;
}
.content{
  margin-top: 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  transition: all .2s ease;
}
.actionButtonsWrapper{
  width: 95%;
  max-width: 1000px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
.settingsButton{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 2rem;

  display: flex;

  border-radius: 1rem;
  padding: .5rem 2rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, .4);

  cursor: pointer;

  transition: scale .3s ease;
}
.settingsButton:hover{
  scale: 1.1;
}
.settingsButton > svg{
  width: 2rem;
  height: 2rem;
  transition: transform .3s ease-in;
}
.settingsButton:hover > svg{
  transform: rotate(180deg);
}
.settingsButton > span{
  margin: auto .5rem;
}
.workingQuestions{
  width: 100%;
  max-width: 1000px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.workingQuestionContainer.show > .displayButton{
  background-color: black;
  color: white;
}
.displayButton{
  flex-grow: 1;
  border-radius: 1rem;

  text-align: center;
  font-size: min(3rem, max(3vw, 2rem));
  padding: 1rem 2rem;

  box-shadow: 0 0 4px rgba(0, 0, 0, .2);

  cursor: pointer;
  transition: scale .2s ease;
  
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.startDurationWrapper{
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 1rem;
}
.startDurationContainer{
  display: flex;
  width: min-content;
  flex-direction: column;
}
.startDurationContainer > span{
  text-wrap: nowrap;
  text-align: left;
}
.start{
}
.duration{
  width: 100px;
}
@media (max-width: 500px){
  .displayButton{
    grid-template-columns: 1fr;
  }
  .startDurationContainer{
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .startDurationContainer > span{
    text-align: center;
  }
}
.workingQuestionText{
  display: flex;
  justify-content: center;
  align-items: center;
}
.displayButton:hover{
  scale: 1.1;
} 
.dropdown{
  /* this is animationing*/
  flex-grow: 1;
  max-height: 0;
  overflow: hidden;
  /* /this is animationing*/

  display: flex;
  flex-direction: column;

  padding: 1rem 1rem;
  font-size: 2rem;
}
/*in .dropdown*/
.startVotingButtonWrapper{
  flex-grow: 1;

  display: flex;
  justify-content: flex-end;
}
.minuteInput{
  width: 100%;
  margin-top: 1rem;
  resize: both;
  border: none;
  outline: none;

  font-size: min(2rem, max(2vw, 1.5rem));
}
.toggleContributeButton .p-button-label{
  font-size: 2rem !important;
}
.showNotesContainer{
  margin-top: 1rem;
}
.showNotesButtonContainer{
}
.showNotesContainer > section{
  font-size: 2rem;
}
