#navbar {
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 95%;
  padding: 0px;
  margin: 0 auto;
}
.nav-right-cnt{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: min-content;
  margin: auto 0;
  align-items: center;
}
#navbar > li {
  font-size: 30px;
}
#nav-account-link{
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
