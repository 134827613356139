.form{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.input{
  width: 500px;
  max-width: 500px;
  flex-grow: 1;
  border: none;
  border-radius: 9px;
  box-shadow: 1px 1px 6px -2px;
  font-size: 1.5rem;
  margin-right: .5rem;
  margin-top: 1rem;
  padding: 18px 36px;
}
.submit{
  background-color: #016fa0;
  border-radius: 9px;
  box-shadow: 1px 1px 6px -2px;
  font-size: 1.5rem;
  margin-top: 1rem;
  color: white;
  border: none;
  padding: 18px 36px;
  cursor: pointer;
}
.loadingOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .5);
}
