body{
  margin: 0;
}
body.no-scroll{
  overflow: hidden;
}
/*
@media print{
  body *:not(.include-print) {
    display: none !important;
  }
}*/
#root{
  --main-color: black;
  --secondary-color: #ff7916;
  --btn-color: rgb(1, 111, 160);
  --btn-color-hover: rgb(1, 98, 139);
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
* {
  font-family: 'Figtree';
}
h1 {
  text-align: center;
  margin: 5rem auto;
}

h2 {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  display: block;
}

a {
  color: black;
}

:visited {
  color: black;
  text-decoration: none;
}

header {
  display: flex;
  justify-content: space-between;
}

.qck-app-corner-logo {
  margin-top: 3rem;
}

.lng-div {
  display: flex;
  flex-wrap: wrap;
}

.lng-btn {
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  margin: 0.1rem;
  padding: 1rem;
}

.lng-btn.contrast{
  color: black;
  background-color: white;
}

.lng-btn.contrast:hover:enabled{
  background-color: #dcdcdc;
  color: black
}

.lng-btn:hover:enabled {
  color: white;
  background-color: gray;
  padding: 1rem;
  border: none;
  border-radius: 4px;
}

.lng-btn:disabled {
  color: rgb(163, 163, 163);
}

@media screen and (max-width: 470px) {
  .qck-app-corner-logo {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .lng-div {
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 361px) {
  
}
.default-button{
}
.default-button{
}
.default-button{
}


