
main{
    display: flex;
    
    flex-direction: column;
    background-color: transparent !important;
    align-items: center;
}

.site-logo{
    width: 3rem;

    margin: 0.5rem;    
}


.start-btn-big{
    font-size: 2rem;
}


.start-btn-small{
    font-size: 1.7rem;
}

.start-btn{
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    width: 80%;
    min-height:4em;
    max-width: 300px;
    padding: 1rem;

    border: none;
    border-radius: 1.5rem;
    
    color: white;
    font-family: 'Figtree', sans-serif;
    font-weight: 400;

    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    background-color: rgb(1, 111, 160);
    
    cursor: pointer;

    transition: all .2s ease;
}

.start-btn:hover{
    background-color:  rgb(1, 96, 137);
}       

.start-btn:active{
    transform: translate(2px, 2px);
    box-shadow: none;
}

.diff-qnr-cnt{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Figtree', sans-serif;
}

.diff-qnr-p{
    margin-bottom: 3px;
    color: black;
}

.diff-qnr-link{
    
    color: black;   
    font-family: 'Figtree', sans-serif;
    font-size: 1.5rem;
    
    outline: 0;
    border: 0;
    background-color: transparent;
    text-decoration: underline;
    
    width: 80%;
    
    cursor: pointer;
}

.diff-qnr-link:hover{
    font-size: 1.55rem;
}

.dark, .contrast{
    color: white;
}