.container{
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}
.container > h1{
  font-size: 2.5rem;
  margin: 0;
}
.container > h2{
  color: rgb(50, 50, 50);
  margin-top: 5px;
}
.backToMeetingContainer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem;
}

.hasStarted{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hasStarted > p {
  font-size: 2.5rem;
}
