:root{
  --count-cnt-max-width: 180px;
  --count-cnt-min-width: 130px;
  --count-cnt-margin: 1rem;
  --change-btn-cnt-width: 8rem;
  --change-btn-cnt-margin: 3rem;
  --nav-height: 53px;
  --nav-margin: 3rem;
  min-height: 100vh;
}

.main{
  display: flex;
  flex-grow: 1;
}
 
#heading-cnt{
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  min-height: calc(var(--nav-height) + var(--nav-margin));
}

#change-data-btn-cnt{
  display: flex;
  width: var(--change-btn-cnt-width);
  align-items: center;
  margin: 0 3rem var(--nav-margin) 0;
}

.change-data-btn{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  height: 4rem;
  cursor: pointer;
}

#delete-rm-btn{
  width: 3rem;
}

#edit-rm-btn{
  width: 4rem;
  margin-right: 1rem;
  margin-left: auto;
}
#heading-h1-cnt{
  margin: 0 auto 0rem auto;
  padding-left: calc(var(--change-btn-cnt-width) + var(--change-btn-cnt-margin));
  display: flex;
  flex-direction: column;
}

#heading-h1{
  font-size: 4rem;
  /* width: fit-content; */
  padding: .2rem 0;
  outline: none;
  margin: 0;
  text-align: center;
  border: none;
  /* text-decoration: underline; */
  /* border: none; */
}

#heading-h1.edit:focus{
  /* text-decoration: underline; */
}

#edit-btns-cnt{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

#heading-h1.edit + #edit-btns-cnt{
  visibility: visible;
  opacity: 1;
}

.edit-btn{
  width: 35%;
  padding: .3rem;
  border-radius: .5rem;
  border: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background-color .2s ease;
}

.gif-loading-cnt{
  position: absolute;
  display: flex;
  width: 0;
  height: 0;
  justify-content: center;
}

.edit-btn.save .gif-loading-cnt img{
  width: 1.5rem;
  height: 1.5rem;
}

.edit-btn.save{
  background-color: rgb(1, 111, 160);
  color: white;
  display: flex;
  justify-content: center;
}

.edit-btn.save:hover{
  background-color: rgb(0, 96, 137);
}

.edit-btn.cancel:hover{
  background-color: gainsboro;
}

.edit-btn.save:active{
  background-color: rgb(0, 83, 119);
}

.edit-btn.cancel:active{
  background-color: rgb(196, 196, 196);
}

@media (max-width: 623px){
  #heading-h1-cnt{
    /* padding-right: calc(var(--change-btn-cnt-width) + var(--change-btn-cnt-margin)) */
    width: 100%;
    padding: 0;
  }  
  #change-data-btn-cnt{
    margin: 1rem auto;
    
  }
}

#loading{
  font-size: 5rem;
}

#nav{
  width: 80%;
  display: flex;
  justify-content: center;
}

#nav-main{
  display: flex;
  width: min-content;
  overflow-x: auto;
  overflow-y: visible;
}

.nav-element{
  margin: 0 2rem;
  cursor: pointer;
  text-align: center;
}

.nav-element.active{
  cursor: default;
}

.nav-element-text{
  font-size: 2rem;
}

.nav-element-hr{
  transition: background-color 0.3s ease;
  background-color: transparent;
  color: aqua;
  height: 4px;
  border: none;
  width: 100%;
}

.nav-element.active > .nav-element-hr{
  background-color: rgb(1, 111, 160);
}

#content{
  display: flex;
  flex-grow: 1;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

#overview-inner-cnt{
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-grow: 1;
}

.overview-counts-cnt{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.overview-counts-aligning-div{
  display: flex;
  justify-content: center;
  height: fit-content;
}

.count-cnt{
  width: 20vw;
  max-width: var(--count-cnt-max-width);
  min-width: var(--count-cnt-min-width);
  height: 130px;
  background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 210%);
  border-radius: 1rem;
  background-size: 120%;
  margin: var(--count-cnt-margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;
}

.count-cnt:hover{
  background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 100%);
  background-size: 190px;
  background-position: -10px; 
}

.count-cnt:hover > .count-cnt-content{
  font-size: 2.5rem;
}

.count-cnt-heading-cnt{
  width: 100%;
  display: flex;
  justify-content: center;
}

.count-cnt-heading{
  font-size: 1.2rem;
  font-weight: 300;
  color: white;
  text-align: center;
  text-wrap: wrap;
  white-space: normal;
  margin-top: .5rem;
}

.count-cnt-content{
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  color: white;
  margin-top: 1.5rem;

  transition: font-size 0.3s ease-in-out;

}

.count-cnt-num{
  margin: 0 3px;
}

#rm-content-cnt{
  display: flex;
}

#btn-emails-cnt{
  margin-left: var(--count-cnt-margin);
  width: 100%;
  max-width: calc(2 * var(--count-cnt-max-width) + 2 * var(--count-cnt-margin));
}

@media (max-width: 900px) {
  #rm-content-cnt{
    justify-content: center;
  } 
  #btn-emails-cnt{
    margin: 0;
  }
}

#btn-show-emails{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  color: black;
  /* background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 180%); */
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: background-color .3s ease;
}

#btn-show-emails:hover{
  background-color: rgb(215, 215, 215);
}

#btn-show-emails:hover > #show-emails-arrow-cnt{
  font-size: 2rem;
}

#btn-show-emails:active{
  background-color: rgb(232, 232, 232);
}

#votes-cnt{
  margin-left: var(--count-cnt-margin);
  width: 100%;
  max-width: calc(2 * var(--count-cnt-max-width) + 2 * var(--count-cnt-margin));
  
}

#votes-show-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  color: black;
  /* background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 180%); */
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: background-color .3s ease;  
}

.show-contents-text{
  width: 100%;
  text-align: center;
  margin: 1rem 1rem .5rem 0rem;
  font-size: 2rem;  
}

.show-arrow-cnt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: font-size .3s ease;
  height: 45px;
  font-size: 1.6rem;
  margin: 1rem;
}

.show-arrow{
  width: 1.7rem;
  height: 1.7rem;
  transition: transform .3s ease;
}

.show-arrow.flip{
  transform: rotateX(180deg);
}

#emails-emails-btn-cnt{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: opacity .5s ease-in-out;
}

#emails-emails-btn-cnt.visible{
  visibility: visible;
  opacity: 1;
  height: auto;
}

#emails-emails-cnt{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity .5s ease-in-out;
}

#participants-list-emails{
  width: 100%;
  margin-bottom: 1rem;
}

#participants-list-func-btns-cnt{
  width: 100%;
}

#participants-email-input{
  width: 100%;
}

#emails-emails-cnt.visible{
  height: auto;
  opacity: 1;
  visibility: visible;
}

.email-cnt{
  width: calc(100% - 2rem);
}

#no-emails{
  margin: 2rem 0;
  font-size: 2.5rem;
  width: 100%;
  text-align: center;
}

@media (max-width: 600px){
    #rm-content-cnt{
        align-items: center;
        flex-direction: column;
    }
    #votes-cnt{
        margin-left: 0;
    }
}


