#votes-data-cnt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 1.8rem;
    
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;

    transition: all .5s ease-in;
}

#votes-data-cnt.show{
    display: flex;
    visibility: visible;
    opacity: 1;
    height: auto;
}

#votes-data-cnt p#avg-votes-cnt{
  margin: 2rem 0 1.5rem 0;
}

#avg-answer-cnt{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
}

#avg-answer-cnt div{
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

#avg-answer-cnt div span{
  margin: .5rem 0;
  width: 100%;
  text-align: center;
  border-bottom: .5px solid rgb(0, 0, 0);
}


#votes-data-cnt p{
    margin: .5rem 0;
    text-align: center;
}
