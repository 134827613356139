.mainContent{
    display: flex;
    width: 100%;
    max-width: 1800px;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.2rem auto 0 auto;
}

.p1{
    font-size: 3.5rem;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.p2{
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}


.fieldsetcont {
    display:flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
}

.fieldset{
    border-width: 5px;
    background-color: #f2f2f2;
    border-radius: 10px;
    font-size: 1.3rem;
    width: 300px;
    min-width: 300px;
    margin: 1.5rem;
    display: flex;
}


.lineCont {
    display: flex;
    align-items:start;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-left: 0;
    min-height: 15rem;
}

.line {
    font-size: 1.5rem;
    font-weight: 600;
}
.line::marker{
  content: '>';
  font-size: 1.75rem;
  font-weight: 600;
}

.distributionCard {
    width: 4.5rem;
    height: 4.5rem;
} 

.startButton {
    /* background-color: #016fa0; */
    /* border: 3px solid #016fa0; */
    border-radius: 6px;
    width: 20rem;
    height: 5rem;
}

.startButton > span {
    font-size: 1.55rem;
}

.qckCont{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.qckBtn {
    width: 50rem;
    border-radius: 6px;
}