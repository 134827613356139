/*
 * unsure if anything in this file is currently used, but keeping it as a patch;
 * */
  .ChangeComunication {
    display: flex;
    flex-direction: column;
    gap: 0px;
    display: flex;
    align-items: center;
  }
  
  .logoutbutton {
    margin-top: 100px;
    background-color: rgb(0, 0, 0);
    margin: 0 auto;
    border: 2px solid black;
    border-radius: 9px;
    font-family: 'Figtree';
    font-style: normal;
    text-align: center;
    padding: 18px 6px;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  
  .diusributionButton:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  }

    
  .logouttext {
    font-family: 'Roboto', sans-serif;
    color: rgb(48, 48, 48);
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    text-align: left;
    /* width: 50%;
      margin-left: 25%; */
    max-width: 450px;
  }

  .dashboard-text{
    margin: 0;
    padding: 0 !important;
  }

  .information {
    margin-left: 5rem
  }
  
