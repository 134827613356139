.container{
  width: 95%;
  margin-top: 1rem;
}
.link{
  display: flex;
  align-items: center;
  text-decoration: none;
}

.link:hover{
  text-decoration: underline; 
}

.arrowLeft{
  transform: rotate(90deg);
}
.arrowRight{
  transform: rotate(-90deg);
}

.textContent{
  margin-left: .5rem;
}
