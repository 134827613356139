.beforeEndContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 11rem 0;
}
.beforeEndCaption{
  text-align: center;
  font-size: min(3rem, max(5vw, 2rem));
}
.beforeEndButton{
  text-align: center !important;
  font-size: min(2rem, max(5vw, 1rem)) !important;
}

.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}
.heading1{
  margin: 0;
  font-size: 2.7rem;
}

.durationList{
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 350px;
}
.durationList > li{
  font-size: 1.1rem;
  text-align: center;
}

.basicDataList{
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  max-width: 500px;
}
.basicDataList > li{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  color: white;
  border-radius: 1rem;
  padding: 2rem 0;
  background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 210%);
  background-size: 120%;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}
.basicDataList > li:hover{
  background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 100%);
  background-size: 190px;
  background-position: -10px; 
}
.basicDataList > li > p{
  text-align: center;
  font-size: 11px;
  margin: 0;
}
.basicDataList > li > span{
  font-size: 1.7rem;
  font-weight: 700;
  transition: scale .3s ease-in-out;
}
.basicDataList > li:hover > span{
  scale: 1.1;
}

@keyframes basicDataHover{
  0%{
    background-position-x: -100%;
  }
  100%{
    background-position-x: 0;
  }
}

.avgVotesGraphContainer{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.avgVotesHeading{
  margin: 3rem 0 2rem 0;
  font-size: 2rem;
}

.headingWorkingQuestions{
  max-width: 500px;
  margin: 3rem 0;
}
.workingQuestionsList{
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 98%;
  max-width: 700px;
  gap: 1rem;
}
.workingQuestionsList > li{
  flex-grow: 1;
}
.workingQuestionOverview{
  flex-grow: 1;
  border-radius: 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, .5);
  padding: 1rem 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  transition: scale .2s ease;
}
.workingQuestionOverview:hover{
  scale: .98;
}
.workingQuestionText{
  font-size: 2rem;
}
.workingQuestionVoters{
  font-size: 1.2rem;
}

.workingQuestionEvalContainer{
  width: 98%;
  margin: 0 auto;
}
.show{
  visibility: visible;
}
.hide{
  visibility: hidden;
  max-height: 0;
}

.notesContainer{

}
.notesHeading{
  text-align: left;
}
.notes{
  margin: 0;
  font-size: 1.5rem;
}

.discussionStarted{
  font-size: 1.7rem;
}
