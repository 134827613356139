.loadingOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
}
.heading{
  font-size: 2rem;
  color: gray;
}
.email{
  font-size: 2.5rem;
}
.actionButtonsContainer{
  list-style-type: none;
  padding: 0;
}
.buttonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem; 
}
.button {
  background-color: rgb(0, 0, 0);
  border: 2px solid black;
  border-radius: 9px;
  font-family: 'Figtree';
  font-style: normal;
  text-align: center;
  padding: 18px 6px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  width: 300px;
}
  
.buttonLabel {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: rgb(48, 48, 48);
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  /* width: 50%;
    margin-left: 25%; */
}

.dashboard-text{
  margin: 0;
  padding: 0 !important;
}

.information {
  margin-left: 5rem
}

