:root{
  --icon-size: 3.5rem;
  --icon-left-offset: .5rem;
}

.regular-meeting-cnt{
  width: 98%;
  max-width: 600px;
  min-width: 250px;
  border: 1px solid black;
  border-radius: 2rem;
  margin: 1rem 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.regular-meeting-cnt:hover{
  background-color: rgb(195, 195, 195);
}

.regular-meeting-cnt:active{
  transition: background-color 0.2s ease;
  background-color: rgb(212, 212, 212);
}

.regular-meeting-heading-cnt{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 1.5rem 0;
}

.qnr-icon{
  position: absolute;
  margin: .5rem 0 0 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.regular-meeting-h1{
  margin: .5rem auto 0 auto;
  padding: 0 calc(var(--icon-size) + var(--icon-left-offset) + 1rem);
}

.regular-meeting-data-cnt{
  width: 100%;
  display: flex;
}

.regular-meeting-overview-cnt{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.regular-meeting-data-span{
  font-size: 1.5rem;
  width: 30%;
  margin: .5rem;
  min-width: 120px;
  text-align: center;
}

#start-btn-div{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: auto;
}
#start-btn-div .margin-div{
  min-height: 3rem;
}
#start-btn-div button{
  background-color: var(--blue-btn-color); 
  color: white;
  border: none;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  font-size: 2.5rem;
  padding: 1rem 5rem;
  cursor: pointer;

  transition: all .1s ease-in;
}
#start-btn-div button:hover{
  background-color: var(--blue-btn-color-hover);
}
#start-btn-div button:active{
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transform: translateX(1px) translateY(1px);
}

#gif-loading-cnt{
  position: absolute;
  display: flex;
  width: 80px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

#gif-loading-cnt img{
  width: 2.5rem;
  height: 2.5rem;
}
