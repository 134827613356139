.container{
  background-color: white;
  height: min-content;
  margin: auto;
  padding: 1.2rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
}
.heading1{
  margin: 0;
  font-size: 3rem;
  margin-top: 2rem;
}
.continueButton{
  font-size: 1.35rem !important;
  width: min-content;
  margin: 0 auto !important;
}
.saveButtonsContainer{
  display: flex;
  justify-content: flex-end;
}
.saveButtonsContainer > button{
  font-size: 1.5rem !important;
}
.saveButton{
  margin-right: .5rem !important;
}

