#results-h1 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 4rem;
  margin-top: 3rem;
  justify-self: center;
}

#qnr-string {
  display: block;
  text-align: center;
}

#improve-label {
  margin-bottom: 2rem;
  margin-top: 64px;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}

#improve-wrapper {
  max-width: 92rem;
  margin: 0 auto;
  padding: 0;
  /*Hide scrollbar*/
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow: scroll;
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(192, 192, 192, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(192, 192, 192, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 87px, 100% 87px, 100% 29px, 100% 29px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#improve-wrapper::-webkit-scrollbar {
  display: none;
}

.improve-text {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  border: 1px solid #000000;
  border-radius: 14px;
  padding: 2rem 6rem;
  margin: 1.5rem 0rem;
  margin-bottom: 3rem;
}

.two-element-flexbox {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 92rem;
  width: 90%;
  align-items: center;
  margin-bottom: 5rem;
  flex-wrap: wrap;
}

.two-element-flexbox.voters-results{
  flex-direction: row;
}

:root {
  font-size: 62.5%;

  --bar-color: #000000;
  --body-color: #222;
  --text-color: white;
  --bar-wrap-back-color: #cfcfcf;
}

.bar-body {
  border: 1px solid #000000;
  border-radius: 14px;
  padding: 10rem 6rem;
  max-width: 80rem;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
}

.title {
  margin-top: 8rem;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 38px;
  margin-bottom: 1rem;
}

.title:first-of-type {
  margin-top: 0;
}

.bar-wrap {
  padding: 0px;
  border-radius: 6px;
  background-color: var(--bar-wrap-back-color);
}

.bar {
  width: 0%;
  height: 60px;
  transition: width 0.15s ease-out;
  background-color: var(--bar-color);
  border-radius: 6px;
}

.percentage {
  align-self: center;

  margin: 10px 0 25px;

  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1.8rem, 3.5vw, 2.6rem);

  color: var(--text-color);
}

.line {
  margin: 0;

  font-size: clamp(1.4rem, 2.5vw, 1.8rem);

  color: var(--text-color);
}

.small-dot {
  height: 2rem;
  width: 2rem;
}

.small-dot-text,
.accordion-title {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  margin: 1.5rem 0rem;
  margin-bottom: 3rem;
  stroke: none;
  max-width: 80%;
  margin: 0 auto;
}

#top-row-data {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1250px) {
  .detailed-vote-wrapper {
    max-width: 84rem;
    margin: 0 auto;
  }

  /* #results-logo {
    top: 2rem;
    left: 2rem;
    height: 38px;
    width: 33px;
  } */
}

@media screen and (max-width: 453px) {
  .improve-text {
    padding: 2rem;
  }

  .bar-body {
    padding: 4rem 2rem;
  }

  .title {
    font-size: 2rem;
    margin-top: 7rem;
  }

  #improve-label {
    font-size: 2.5rem;
  }
}

#distribuIcon {
  width: 7em;
  height: 7em;
  text-align: center;
  justify-self: flex-start;
  margin-left: 1rem;
  margin-right: auto;
}