main{
    font-family: 'Figtree', sans-serif;

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

#navbar {
    margin-top: 0;
}

.site-logo-link{
    width: min-content;
    height: min-content;

    margin-left: 3vh;
    margin-top: 3vh;

    justify-self: flex-start;
    align-self: flex-start
}

.site-logo{
    width: 3rem;
}

.capt-p{
    font-size: 2.2rem;
    font-weight: 800;

    width: 90%;

    text-align: center;
    color: #222;
}

.capt-p.dark, .capt-p.contrast{
    color: white;
}

.about-capt-p{
    width: 80%;

    font-size: 1.2rem;
    font-weight: 600;

    text-align: center;
    color: #222;
}

.about-capt-p.dark, .about-capt-p.contrast{
    color: white;
}

.links-cnt{
    display: flex;

    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 500px;
    
    border-radius: 1rem;

    margin: 1rem;
    background-color: rgb(1, 111, 160);
}

.links-cnt-link{
        
    color: white;
    
    text-align: center;
    text-decoration: none;
    padding: 1rem;
    font-size: 1.3rem;
    width: 33%;   
    cursor: pointer;

    border-color: transparent;
    background-color: transparent;
}

.links-cnt-link:hover{
    text-shadow: 0.15rem 0.15rem 1rem black;
}

.eula-info{
    margin: 0;
    flex-grow: 1;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.contact-link{
    margin-right: 3rem;   
}

.more-info{
    margin-left: 3rem;   
}

/* @media(max-width: 420px){
    .capt-p{
        font-size: 5vw;
    }

    .about-capt-p{
        font-size: 3vw;
    }
} */

