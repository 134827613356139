.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading1{
  margin-bottom: .5rem;
  font-size: 4rem;
  line-height: 4.2rem;
}
.heading2{
  font-size: 1.6rem;
  color: gray;
  margin-bottom: 4rem;
}
.heading3{
  font-size: 3.2rem;
  text-align: center;
}
.emailP{
  font-size: 2rem;
  text-align: center;
}
.form{
  display: flex;
  flex-direction: column;
  max-width: 290px;
  width: 100%;
}
.emailInput{
  font-size: 2rem;
  padding-left: 1rem;
}
.saveLoginContainer{
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}
.stayLoggedInCheckbox{
  margin-left: .5rem;
}
.saveLoginLabel{
  font-size: 1.5rem;
  margin-left: .5rem;
}
.submitButton{
  font-size: 2rem;
  display: grid;
  place-items: center;
  padding: 1.5rem 0;
  border-radius: 1.05rem;
  background-color: black;
  background: black;
  outline: black;
  border: none;
  border-color: black;
}
.submitButton:focus{
  box-shadow: none;
}
.otpContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.otpLabel{
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}
.otpInput input{
  font-size: 2rem !important;
}
.clickLinkP{
  text-align: center;
  font-size: 2rem;
  margin: 0;
}
.headingOr{
  text-align: center;
  font-size: 2rem;
}
