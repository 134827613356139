.container{
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  border-radius: 1rem;
  background-color: white;
  max-width: 98vw;
  margin: auto 0;
  height:fit-content;
}
.meetingType{
  font-size: 2.4rem;
  margin: 0;
  margin-bottom: .5rem;
  font-weight: 700;
}
.heading1{
  margin: 0;
  font-size: 2.2rem;
}
.heading2{
  margin: 0 auto;
  color: gray;
  font-size: 1.6rem;
  max-width: 500px;
}
.teamName{
  margin-bottom: 2rem;
}
.sliderContainer{
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.slider span{
  font-size: 1.3rem !important;
}
.questionContainer{
  margin: 1rem 0;
}
.question{
  font-size: 2rem;
  margin: 0;
  text-align: center;
}
.text{
  width: 100%;
  resize: none;
}
.submitButtonContainer{
  display: flex;
  justify-content: space-evenly;
}
.dontApplyButtonContainer{
  display: flex
}
.dontApplyButtonContainer > p{
  margin: auto .5rem 0;
  font-size: 1.2rem;
}
.nameInput{
  font-size: 2rem;
  border: none;
  outline: none;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  padding-left: 1rem;
  width: min-content;
  margin: 0 auto;
  margin-bottom: 1rem;
}