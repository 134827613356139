.enterCodeDiv {
  background-color: #016fa0;
  display: flex;
  border-radius: 30px;
  align-items: center ;
  flex-wrap:wrap;
  font-size: 40px;
  justify-content: center;
  width: 95vw;
  max-width: 800px;
  padding: .8rem 0;
  margin: 0 auto;
}
.inviteRecievedCapt{
  font-size: 35px;
  color: white;
  width: 50%;
  text-align: center;
}
.idField {
  width: 40%;
  background-color: white;
  margin:  0px 8px 0px 0px;
  padding: 0px 5px 0px 5px;
  display: flex;
  border-radius: 30px;
  align-items: center ;
  min-width: 300px;
  max-width: 80%;
}
.qnrId {
  width: 78%;
  max-width: 450px;
  margin: 0px 0px 0px 5px;
  border: none;
  /* border: 2px solid black; */
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 20px;
  text-align: left;
  height: 40px;
}
.searchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  background-color: #016fa0 !important;
  min-width: 0px !important;
  min-height: 0px !important;
  font-size: 30px !important;
  width: 40px !important;
  height: 40px !important;
}
.searchBtn > svg{
  transform: rotateZ(270deg);
}
