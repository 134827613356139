:root{
  --blue-btn-color: rgb(1, 111, 160);
  --box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); 
  --blue-btn-color-hover: rgb(1, 98, 139);
}

main{
  flex-grow: 1;
  display: flex;
  margin: 0;
}

#cq-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cq-content label{
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

#cq-content input{
  font-size: 1.6rem;
  flex-grow: 1;
  width: 90%;

  max-width: 600px;
  border: none;
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: .8rem;
  padding: .5rem 1rem;
}

#cq-content > div{

}

#cq-content > button{
  margin-top: 2rem;
  border: none;
  color: white;
  border-radius: 1rem;
  font-size: 2.5rem;
  padding: .5rem 2rem;
  background-color: var(--blue-btn-color);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  
  cursor: pointer;
  transition: all .2s ease;
}

#cq-content > button:hover, #cq-content > button:focus{
  background-color: var(--blue-btn-color-hover);
}

#cq-content > button:active{
  background-color: var(--blue-btn-color-hover);
  transform: translate(2px, 2px);
  box-shadow: none;
}
