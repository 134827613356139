.container{
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  border-radius: 1rem;
  background-color: white;
  height: min-content;
  margin: auto 0;
  max-width: 90vw;
}
.heading{
  margin: 0 auto;
  font-size: 3rem;
  width: min-content;
  text-wrap: nowrap;
}
.heading2{
  color: gray;
  max-width: 800px;
}
.inviteesList{
  padding: 0;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 1rem;
}
.invitee{
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
  padding: 1rem;
  
  font-size: 2rem;
}
@media (max-width: 800px){
  .inviteesList{
    grid-template-columns: 1fr;
  }
}
.inviteeId{
  display: flex;
  flex-grow: 100;
  justify-content: flex-start;
  align-items: center;

  margin-right: 1rem;
  overflow: hidden;
}
.circle{
  height: 3rem;
  width: 3rem;
  border-radius: 2rem;
  display: grid;
  place-items: center;
  margin-right: 4px;
  color: white;
}
.email{
  font-size: 2rem;
}
@media (max-width: 350px){
  .email{
    overflow: hidden;
    font-size: 1.5rem;
  }
  .circle{
    font-size: 1.5rem;
    height: 2rem;
    width: 2rem;
  }
  .heading{
    font-size: 2.5rem;
  }
  .heading2{
    font-size: 1.5rem;
  }
}
.feedbackStatus{
  font-size: 1.3rem;
  color: orange;
}
.feedbackStatus.submited{
  color: green;
}

.buttonsContainer{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0;
  list-style-type: none;
}
.buttonsContainer .actionButton{
  width: 120px !important;
}
