#set-duration-modal-h2 {
  margin-top: 5rem;
}

.set-duration-modal {
  max-width: 90%;
  padding: 5rem;
}

@media screen and (max-width: 900px) {
  .set-duration-modal {
    max-width: 90%;
    padding: 0.5rem;
  }
}
