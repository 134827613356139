#container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#field {
    font-family: "Montserrat Bold", "Montserrat", sans-serif;
    background-color: #f2f2f2;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    color: #333333;
}

#submitButton {
    background-color: #016fa0; 
    border: none;
    border-radius: 9px;
    font-family: 'Figtree';
    font-style: normal;
    font-size: 1.75rem;
    color: white;
    cursor: pointer;
    font-weight: 600;
    padding: 1.8rem;
  }
  
  #submitButton:hover {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  }

  .field {
  border: none;
  border: 1px solid rgb(114, 114, 114);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  padding: 1rem 0.5rem;
  }

  .field:focus {
    outline: none;
    border: 1px solid rgb(114, 114, 114);
  }