.linksWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.linkGuide{
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
}
.createLink,
.createLinkTitle{
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 2rem;
  max-width: 100%;
}
.createLink{
  padding: 3px 0;
  overflow-x: auto;
  overflow-y: hidden;
}
.qrCode{
  background-color: #ececec;
  max-width: 60rem;
  padding: 15px 15px;
  border-radius: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.qnrId{
  background-color: #ececec;
  border-radius: 9px;
  text-align: center;
  padding: 30px 15px;
  flex-grow: 1;
  max-width: 60rem;
}
.qnrLinkSpan{
  overflow: scroll;
}
.qnrLinkSpan:hover{
  background-color: #dadada;
  cursor: pointer;
}
.copySvg{
}
