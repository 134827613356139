.wrapper{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
}
.dropdown *{
  font-size: 1.3rem;
}
.dropdown > *{
  font-size: 1.3rem;
}
.dropdown{
  font-size: 1.3rem;
}

.input{
  flex-grow: 1;
  padding-top: .8rem;
  padding-bottom: .8rem;
  display: flex;
}
.input > input{
  flex-grow: 1;
}
@media (max-width: 650px){
  .wrapper{
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .input{
    order: -1;
  }
  .input > input{
    margin: 0 !important;
  }
}
