main{
  display: flex;
  flex-direction: column;
}

main p{
  font-family: 'Figtree';
  text-align: center;
}

#intro-p{
  font-size:3rem;
  font-weight: 650;
  max-width: 90%;
}

#intro-1-p{
  font-size: 2rem;
  font-weight: 550;
  max-width: 80%;
}

#steps-ol{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  max-width: 1200px;
  padding: 0;
  margin: auto;
}

#steps-ol li{
  font-size: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.li-image{
  max-width: 100%;
  width: 600px;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
