.button{
  background-color: transparent;
  border: none;
  outline: none;
  width: 4.5rem;
  height: 4.5rem;

  transition: scale .2s ease;
}
.button:hover{
  scale: 1.05;
  cursor: pointer;
}
.button:active{
  scale: .95;
}
.copyPopup{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  font-size: min(2.5rem, max(4vw, 2rem));
  background-color: lightgray;
  padding: 1.2rem 3rem;
  border-radius: 1rem;

  opacity: 0;
  bottom: 0;
  text-align: center;
}
.copyPopup.show{
  visibility: visible;
  opacity: 1;
  bottom: 15vh;
  animation: show 3.3s ease;
}

@keyframes show{
  from{
    bottom: 0;
    opacity: 0;
  }
  10%{
    bottom: 15vh;
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
