.feedbackQuestionList{
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.feedbackQuestion{
}
.answersList{
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-size: 1.5rem;
}
.answer > p{
  flex-grow: 1;
  margin: 0;
}
.questionText{
  text-align: center;
  font-size: 2rem;
}
.slider{
  width: 100%;
  height: 7px;
  border-radius: 6px;
  background-color: darkgray;
  display: flex;
  align-items: flex-end;
}
.circleGroup{
  width: 20px;
  height: 20px;
  position: absolute;
}
.circle{
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  border-radius: 20px;
  position: absolute;
}
.details{
  font-size: 1.5rem;
  border: 1px solid black;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;
  cursor: pointer;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;         
}
.detailsHeading{
  margin: 1rem 0;
  font-size: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.detailsHeading > span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.headingText{
  grid-column: 2;
}
.answerCount{
  font-size: 1.5rem;
}
.opinion{
  display: flex;
}
.opinion > span{
  display: flex;
  align-items: center;
  height: min-content;
  margin-right: .5rem;
}
.opinion .circle{
  margin-right: .5rem
}

