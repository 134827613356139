.qnrHeadingsContainer{
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.qnrItem{
  width: 80vw;
  max-width: 500px;
  min-width: 250px;
  height: 9rem;
  border-radius: 1rem;
  border: 1px solid black;
  margin: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.qnrItem:hover{
  transition: background-color 0.3s ease;
  background-color: rgb(195, 195, 195);
}

.qnrItem:active{
  transition: background-color 0.2s ease;
  background-color: rgb(212, 212, 212);
}

.icon{
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.delQnrBtn{
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem 1rem;
  background-color: transparent;
  outline: transparent;
  border: transparent;
  margin: 0 5px 0 auto;
  padding: 0 0 3rem 3rem;
  z-index: 997;
  cursor: pointer;
}

.delQnrBtn:hover{
  background-size: 1.5rem 1.5rem
}

.delQnrBtn:active{
  width: 9px;
  height: 9px;
  margin-bottom: 1px;
}


.qnrPropertiesCnt{
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
}

.qnrName{
  font-size: 2.5rem;
  margin: 1rem auto 1rem auto;
  padding: 0 1rem;
  flex-grow: 1;
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
  text-decoration: none;
  cursor: default;
  overflow: hidden;
}

.qnrProperty{
  position: relative;
  margin: auto;
  font-size: 1.4rem;
}


.popupBtn{
  position: relative;
  margin: auto;
  border-color: transparent;
  padding: 0 2rem 0 2rem;
  border-radius: .5rem;
  cursor: pointer;
}

#generalInfoCnt{
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 500px;
  min-width: 200px;
}

.qnrIconCnt{
  position: relative;
  margin: 0 auto 0 5px;
  max-width: 1px;
  max-height: 1px;
}

.qnrIcon{
  width: 3rem;
  height: 3rem;
}

.qnrTopSection{
  margin-top: 5px;
  max-height: 1px;
  display: flex;
}
