#dashboard-nav{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
} 

.nav-el {
  margin: 1rem;
  font-size: 2rem;
  text-decoration: none;
  text-align: center;
  color: grey;
  cursor: pointer;
}

.nav-el.selected{
  font-size: 3rem;
  color: black;
  cursor: default;
}
