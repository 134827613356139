.detailed-vote-wrapper {
  max-width: 92rem;
  margin: 5rem auto;
}

.detailed-vote-name {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 64px;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  text-align: left;
}

.detailed-vote-text {
  margin: 2rem auto 9rem;
  padding: 3rem 6rem;

  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;

  border: 1px solid #000000;
  border-radius: 14px;
  max-width: 90rem;
  /*Hide scrollbar*/
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow: scroll;
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(192, 192, 192, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(192, 192, 192, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 87px, 100% 87px, 100% 29px, 100% 29px;
}

#results-line {
  margin: 5rem auto 2rem;
  max-width: 100%;
  position: relative;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  overflow: visible;
}

.result-dots {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  /* top: -10px; */
  font-family: 'Figtree';
  font-weight: bold;
  text-align: center;
  line-height: 2.5rem;
  overflow: visible;
}

.downloadButton:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1250px) {
  .detailed-vote-wrapper {
    max-width: 84rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 453px) {
  .detailed-vote-text {
    padding: 2rem;
  }
}
