.goBackButton {
    color: white;
    background-color: black;
    padding: 20px;
    font-size: 20px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition: 100ms;
}

.goBackButton:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
}