.accordion-title {
  text-align: center;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  margin: 1.5rem auto;
}

.accordion-wrapper {
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 100%;
}

.accordion-content-hidden {
  max-height: 0px;
  overflow: hidden;
}

.accordion-content {
  max-height: 100%;
  overflow: visible;
}

.accordion-vote-date {
  color: darkgray;
}

.accordion-voter-data {
  height: min-content;
  margin-left: .5rem;
  font-weight: 700;
}

.accordion-voter-msg {
  font-weight: 400;
  padding-left: 0.5rem;
  line-height: 3rem;
}
.initials-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-dot-text {
  display: flex;
  position: relative;
  align-items: center;
}

.small-dot-initials {
  position: absolute;
  font-size: 1rem;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  top: 0.4rem;
  max-width: 15px;
  overflow: hidden;
}
