.h1{
  margin: 4rem auto 1rem;
  font-size: 3rem;
}
.h2{
  font-size: 2rem;
  color: darkgray;
  margin-top: 0;
  max-width: 95vw;
}
.agendaSection{
  width: 90%;
  max-width: 800px;
}
.agendaSection > div{
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 2rem;
}
.agendaSection > div > button{
  font-size: 2rem;
}
.inviteLinkContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  gap: 1rem;
  max-width: min(500px, 90vw);
  background-color: darkgray;
  margin: 1rem auto;
  border-radius: 1rem;
}
.inviteLinkContainer > a{
  color: white;
  font-size: 2rem;
  text-decoration: none;
  text-align: center;
}
.resultsButtonDiv{
  display: flex;
  margin: 2rem;
  justify-content: center;
}
.resultsButtonDiv > button{
  font-size: 2rem;
}
.show{
  display: none;
}
.hide{
  display: initial;
}
.buttonsContainer > button{
  background-color: transparent;
  color: black;
  outline: none;
  border: none;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  transition: all .2s ease;
}
.buttonsContainer > button:hover{
  transform: translate(1.5px, -1.5px);
  box-shadow: -1.5px 1.5px 10px rgba(0, 0, 0, .2);
}
.buttonsContainer > button:active{
  transform: translate(0px, -0px);
  box-shadow: -0px 0px 10px rgba(0, 0, 0, .2);
}
