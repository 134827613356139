.navWrapper{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  margin-top: 40px;
}
.backToMeetingWrapper{
  flex-grow: 1;
  padding: 0 2rem 2rem 2rem;

  display: flex;
  justify-content: flex-end;
}
.nav{
  flex-grow: 1;
  
  display: flex;
  justify-content: space-evenly;
  overflow-x: auto;
}
.navLink{
  font-size: 2rem;
  text-decoration: none;
  text-align: center;
  flex-grow: 1;
  margin: 0 5px;

  transition: scale .2s ease;
}
.navLink:hover{
  scale: .95;
}
.navLink:active{
  scale: 1;
}
.active{
  color: var(--secondary-color);
}
.contentWrapper{
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.content{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
