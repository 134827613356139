:root{
  --blue-btn-color: rgb(1, 111, 160);
  --blue-btn-color-hover: rgb(1, 98, 139);
}

#participants-list{
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

#participants-list-func-btns-cnt{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#new-email-btn{
  padding: 6px 12px 6px 12px;
  color: rgb(255, 255, 255);
  margin: auto 1rem auto auto;
  border: none;
  border-radius: 0.7rem;
  background-color: var(--blue-btn-color);
  cursor: pointer;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.2); 
  font-size: 1.6rem;
  transition: box-shadow 0.2s ease; 
}

#new-email-btn:hover{
  background-color: var(--blue-btn-color-hover);  
}

#new-email-btn:active{
  box-shadow: none;
}

#new-email-btn-icon{
  width: 1rem;
  height: 1rem;
  margin: 0 0 0 5px;
}

#no-emails-p{
  font-size: 2.5rem;  
  text-align: center;
  width: 100%;
}

#participants-list-emails{
  display: flex;
  flex-direction: column;
}


#participants-email-input{
  min-width: 200px;
  margin: 0 0 1rem 0;
  flex-grow: 1;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); 
  border-radius: 2rem;
  border: none;
  font-size: 1.5rem;
  outline: none;
  padding: 1rem;
  display: flex;
}

.two-el-flexbox{
  width: 100%;
  display: flex;
  margin: .5rem 0;
  align-items: center;
  flex-wrap: wrap;
}

#participants-count{
  color: rgb(118, 118, 118);
  margin-left: auto;
  margin-right: 1rem;
}

#invalid-input{
  color: red;
  margin-left: 2rem;
  font-size: 1.5rem;
}

