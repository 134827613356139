.joining{
  font-size: 3rem;
  margin: auto 0;
}
.alreadyVoted {
  margin: auto;
}
.alreadyVoted h1{
  font-size: 2.5rem;
  margin: 0 auto;
}
.alreadyVoted h2{
  font-size: 2rem;
}
.resultsContainer{
  display: flex;
  flex-direction: column;
}
.resultsContainer button{
  font-size: 2rem !important;
  margin: 0 auto;
}
