#rm-confirm-delete-cnt{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#rm-confirm-delete-cnt h1{
  margin: 0;
  width: 80%;
  font-size: 2.34rem;
  text-align: center;
}
#rm-confirm-delete-cnt p{
  font-size: 1.2rem;
  text-align: center;
  margin: .5rem 0 0 0;
  width: 90%;
  color: gray;
}

#rm-confirm-input-form{
  margin: 2rem 0;   
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#rm-confirm-input-form .rm-confirm-delete-text{
  margin-top: .5rem;
  color: gray;
}

#rm-confirm-input-form input{
  width: 90%;
  border: none;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.2); 
  border-radius: 1rem;
  padding: .5rem 1.5rem;

}

#rm-confirm-input-form label{
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: gray;
}

.two-el-flexbox{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.two-el-flexbox button{
  margin: 0 .5rem;
  padding: 1rem 2rem;
  border: none;
  font-weight: 200;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.4rem;
  transition: box-shadow 0.2s ease; 
  background-color: lightgray;
  color: rgb(80, 80, 80);
}

.two-el-flexbox .confirm-btn{
  /* background-color: red; 
  color: white; */
  cursor: default;
}

.two-el-flexbox .confirm-btn.active{
  background-color: red; 
  color: white;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
}

.two-el-flexbox .confirm-btn.active:hover{
  background-color: rgb(198, 0, 0);
}

.two-el-flexbox .cancel-btn{
  transition: background-color .2s ease-in-out;
}

.two-el-flexbox .cancel-btn:hover{
  background-color: rgb(122, 122, 122); 
}