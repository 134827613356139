#loading-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1010;
  justify-content: center;
  align-items: center;

  display: none;
}

#loading-overlay.show{
  display: flex;
}

#loading-overlay img{
  width: 4rem;
  height: 4rem;
}