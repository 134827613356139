.meeting-type-option-cnt{
  /* padding: 2rem; */
  width: 100%;
  margin: 2rem 0 2rem 0;
  padding: 1rem 0 1rem 0;
  border-radius: 1rem;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); 
  display: flex;
  align-items: center;

  font-size: 1.2rem;
}

.meeting-type-option-button{
  margin: 0 .5rem 0 .5rem;
  border: none;
  border-radius: 0.5rem;
  width: 15px;
  height: 15px;
  /* box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);  */
  cursor: pointer;
}

.meeting-type-option-button.active{
  background-color: #016fa0;
}

.qnr-type-icon{
  margin-right: .5rem;
}
