#icon-cnt{
  position: absolute;
  height: 0;
  width: 0;
}

#general-info-cnt{
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 800px;
  min-width: 200px;
}
.qnr-type-icon{
  width: 4rem;
  height: 4rem;
}
