.confirmDeleteCnt{
  position: fixed;
  left: 50%;
  top : 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 80%;
  /* background-color:  rgb(122, 122, 122); */
  background-color: white;
  border-radius: 1rem;
  border: 1px solid black;
  padding: 3rem 0 3rem 0;
  z-index: 999;
}
.popupBtn{
  position: relative;
  margin: auto;
  border-color: transparent;
  padding: 0 2rem 0 2rem;
  border-radius: .5rem;
  cursor: pointer;
}

.confirmDeleteText{
  text-align: center;
  font-size: 2rem;

}

.confirmDeleteContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirmDeleteBtnCnt{
  display: flex;
  margin: 1rem 0 1rem 0;
  color: rgb(100,100,100);
}

.confirmDeleteBtn{
  background-color: red;
  color: white;
}

.confirmDeleteBtn:hover{
  background-color: rgb(164, 0, 0);
}

.cancelDeleteBtn{
  background-color: lightgrey;
}

.cancelDeleteBtn:hover{
  background-color: grey;
}
