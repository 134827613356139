.redirect-btn {
  background-color: #ececec;
  margin-bottom: 2rem;
  font-family: 'Figtree';
  text-align: center;
  font-size: 1.6rem;
  width: 80%;
  font-weight: 550;
  border-radius: 0.5rem;
  padding: 1.5rem;
  border: 0.3px solid #000000;  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.2);  
  border: 1px solid black;
  transition: all .2s ease;
}

.redirect-btn:hover{
  background-color: #cdcbcb;
}

.redirect-btn:active{
  background-color: #ececec;
  transform: translate(2px, 2px);
  box-shadow: none;
}

.redirect-btn p{
  margin: auto;

}

.redirect-btn svg{
  height: 1.6rem;
  margin-right: 0.5rem;
}

.redirect-btn-label {
  font-family: 'Figtree';
  text-align: center;
  font-size: 2rem;
  width: 80%;
  font-weight: 550;
}

.redirect-btn.contrast, .redirect-btn.dark{
  color: black
}

.redirect-btn

.dark, .contrast{
  color: white
}