.mainContent{
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.navWrapper{
  display: flex;
  flex-grow: 1;
  margin: 2rem 1rem;
}
.nav{
  width: 800px;
  display: flex;
  margin: auto;
}
.navTitle{
  flex-grow: 1;
  font-size: 3rem;
  text-align: center;
  height: min-content;
  margin: auto 0;
}
.minutesField{
  width: 100%;
  font-size: 2rem;
}
.loading{
  font-size: 4rem;
}
.workingQuestions{
  list-style-type: none;
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.workingQuestionContainer{
  flex-grow: 1;
}
.workingQuestionContainer.show > .displayButton{
  background-color: black;
  color: white;
}
.displayButton{
  border-radius: 1rem;
  text-align: center;
  font-size: min(3rem, max(3vw, 2rem));
  padding: 1rem 2rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  transition: scale .2s ease;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.workingQuestionText{
  font-size: 3rem;
}
.startDurationWrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 1rem;
}
.startDurationContainer{
  display: flex;
  flex-direction: column;
  width: 100px;
  justify-content: flex-start;
  font-size: 1.3rem;
}
.startDurationContainer > span{
  text-align: start;
  text-wrap: nowrap;
}
@media(max-width: 500px){
  .displayButton{
    grid-template-columns: 1fr;
  }
  .startDurationContainer{
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .startDurationContainer > span{
    flex-grow: 0;
  }
}
/* dropdown*/
.dropdown{
  display: none;
  
  flex-direction: column;
  padding: 1rem;
}
.workingQuestionContainer.show > .dropdown {
  display: flex;
}
.minute{
  font-size: min(2rem, max(2vw, 1.5rem));
  text-align: left;
}
.noMinute{
  font-size: min(2rem, max(2vw, 1.5rem));
  color: gray;
}
.voteButtonWrapper{
  display: flex;
  justify-content: center;
}
/* /dropdown*/
.hasntStarted{
  font-size: 3rem;
}
/* for results */
.popupContainer{

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;
  z-index: 999;

  justify-content: center;
}
/* /for results */
.overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  overflow: scroll;

  display: grid;
  place-items: center;
  z-index: 9999;
}
.contributeContainer{
  width: 100%;
  display: flex;
}
