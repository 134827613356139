.content{
  margin-top: 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  transition: all .2s ease;
}
.heading1{
  margin: 0;
  font-size: 3rem;
}
/* overview section */
.overview{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap
}
.overview > div{
  flex-grow: 1;
  max-width: 500px;
  min-width: 300px;

  display: flex;
  flex-direction: column;
}
.propsList{
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  list-style-type: none;
}
.propsList > li{
  font-size: 2rem;
  text-align: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 1.3rem;
  padding: .5rem;
}
.workingQueston{
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.workingQueston p{
  margin: 0;
  display: block;
  text-overflow: clip;
  word-wrap: break-word;
  white-space: normal;
  position: relative;
}
.workingQuestionType{
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.modifyButton{
  width: min-content !important;
  margin: 0 auto !important;
  font-size: 1.5rem !important;
}
.empty{
  font-size: min(2.5rem, max(2rem, 2.5vw));
  text-align: center;
  padding-bottom: 2rem;
  margin: auto 0;
}
/* /overview section */

.startWrapper{
  padding: 2rem .5rem;
}
.startButton{
  font-size: 2rem !important;
  background-color: var(--secondary-color) !important;
}
