:root{
  --blue-btn-color: rgb(1, 111, 160);
  --blue-btn-color-hover: rgb(1, 98, 139);
}

.input-section{
  width: 90%;
  max-width: 400px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  
}

#heading{
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 2rem;
}

#heading #heading-h1{
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 600;
  margin: 25px 0;
}

#heading-h2{
  width: 100%;
  max-width: 650px;
  text-align: center;
  font-size: 2rem;
  color: #969696;
  font-weight: 400;
}

#reg-meeting-data-form{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#meeting-name{

}

.input-label{
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  max-width: 500px;
}

#meeting-name-input{
  width: 100%;
  padding: 0.7rem 0 0.7rem 0.7rem;
  border: none;
  border-radius: 1rem;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); 
}

#meeting-type{
  margin-top: 4rem;
  width: 100%;
  max-width: 800px;
}

#meeting-type-input{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.two-type-wrapper{
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 300px;
  /* margin: auto; */
}

#participants{
  width: 90%;
  max-width: 500px;
}

#participants-input{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



#participants-label-2{
  width: 100%;
  max-width: 650px;
  text-align: center;
  font-size: 1.1rem;
  color: #969696;
  font-weight: 400;
}

@media (max-width: 700px){
  #meeting-type-input{
    justify-content: center;
  }
}

#submit-form-cnt{
  width: 80%;
  max-width: 600px;
  display: flex;
}


#submit-form-btn{
  font-size: 2rem;
  color: white;
  padding: 1.5rem 4rem 1.5rem 4rem;
  outline: none;
  border: none;
  margin: 5rem 0 0 auto;
  border-radius: 1rem;
  background-color: rgb(86, 86, 86);
  cursor: default;
  color: rgb(120, 120, 120);
  
  display: flex;
  justify-content: center;
}

#loading-wheel-cnt{
  position: absolute;
  height: 2rem;
  width: 2rem;
}
#submit-form-bnt #loading-wheel-cnt img{
  width: 100%;
  height: 100%;
}

#submit-form-btn.btn-active:hover{
  background-color: var(--blue-btn-color-hover);
}  

#submit-form-btn.btn-active{
  background-color: rgb(1, 111, 160);
  cursor: pointer;
  color:white;
}

@media (max-width: 600px){
  #submit-form-btn{
    margin: 5rem auto 0 auto;
  }
}