.email-cnt{
  width: 90%;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); 
  border-radius: 2rem;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.email-txt-cnt{
  font-size: 1.5rem;
  margin-left: 1rem;
}

.email-del-btn{
  margin-right: 1rem;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
}