#GK-img {
  max-width: 20rem;
  border-radius: 50%;
  display: block;
  margin: 10rem auto;
}

#thx-h1 {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  margin: 4rem auto;
}

#thx-msg {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
}

/* .thx-wrapper {
  display: flex;
  flex-direction: column;
} */
@media screen and (max-width: 453px) {
  #GK-img {
    max-width: 11rem;
  }
}
