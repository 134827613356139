.wrapper{
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  max-width: 92rem;
  margin: 3rem auto 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.durationButton,
.customDurationSubmit{
  border-radius: 9px !important;
  padding: 18px 36px !important;
  font-size: 1.5rem !important;
  margin: 1.5rem !important;
  box-shadow: 1px 1px 6px -2px !important;
  transition: all .2s ease !important;
  height: min-content;
}
.durationButton{
  display: flex;
  justify-content: center; 
  align-items: center;
  color: black !important;
  background-color: white !important;
}
.selected{
  color: white !important;
  background-color: black !important;
}
.durationButton:hover{
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important;
}
.durationButton:active{
  background-color: white;
  color: black;
  border: 1px solid black;
}
.customDurationForm{
  justify-content: center; 
  align-items: center;

  flex-grow: 1;
}
.customDurationInput{
  max-width: 3rem;
  padding: 18px 36px;
  border-radius: 9px;
  font-size: 1.5rem;
  /* max-width: 10rem; */
  box-shadow: 1px 1px 6px -2px;
  margin-right: 0.5rem;
  border: none;
}
.customDurationInput:focus{
  outline: none;
}
.customDurationSubmit{
  text-align: center;
  border: none;
  color: white;
  background-color: black;
}
.customDurationSubmit:hover {
  cursor: pointer;
}
.customDurationSubmit:active {
  transition: background-color 0.1s ease-in;
  background-color: white;
  color: black;
  border: 1px solid black;
}
.loadingOverlay{
  position: absolute;
  z-index: 1000;
  background-color: rgba(255, 255, 255, .5);
  width: 100%;
  height: 100%;
}
