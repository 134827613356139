#user-agreement-wrapper {
  margin: 5rem auto 1rem;
  display: block;
  text-align: center;
}

#user-agreement-content {
  font-family: 'Figtree';
  font-style: normal;
  font-size: 1rem;
  line-height: 3rem;
}

#user-agreement-link {
  color: black;
}

#user-licence-page-wrapper {
  max-width: 90%;
  margin: 1rem auto 5rem;
  font-family: 'Figtree';
  font-style: normal;
  font-size: 1.2rem;
}

.user-licence-list-style-none {
  list-style: none;
}

.user-agreement-sub-title {
  font-size: 2rem;
}
