/* #create-h1 {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 28px;
    max-width: 282px;
    margin: 0 auto;
    margin-top: 40px;
} */

#warning-msg {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  border: 1px solid black;
  border-radius: 9px;
  padding: 15px;
  width: 90%;
  max-width: 63rem;
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 35px;
  margin-bottom: 35px;
}

#qnr-link {
  border: 1px solid black;
}

#qnr-link-span:hover {
  background-color: #dadada;
  cursor: pointer;
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.link-guide {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0 auto;
  margin-top: 5rem;
}

.qnr-id {
  background-color: #ececec;
  padding: 15px 30px;
  border-radius: 9px;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  max-width: 60rem;
}

.create-link-title,
.create-link {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 2rem;
  max-width: 100%;
  overflow: visible;
}

.create-link {
  font-weight: 600;
  line-height: 1.5em;
}

#copy-svg {
  max-width: 32px;
}

#copy-svg:hover {
  fill: gray;
}

#copy-alert {
  position: sticky;
  bottom: 1rem;
  max-width: 90%;
  margin: 1rem auto;
  margin-bottom: 0;
  background-color: #dadada;
  color: black;
  border: 1px solid darkgray;
  animation: 1s ease-in;
  align-items: center;
  font-size: 1.5rem;
}

#copy-alert {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.qr-code {
  background-color: #ececec;
  width: 80%;
  max-width: 60rem;
  padding: 15px 30px;
  border-radius: 9px;
  text-align: center;
}

#results-span {
  margin-top: 3rem;
}

.link-span {
  max-width: 100%;
}

@media only screen and (max-width: 850px) {
  #qck-app-logo-create {
    top: 2rem;
    left: 2rem;
    height: 38px;
    width: 33px;
  }
}

@media only screen and (max-width: 600px) {
  .links-wrapper {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .create-link-title,
  .create-link,
  #copy-alert {
    font-size: 20px;
  }
}

#headers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #333333;
  font-family: 'Figtree';
  text-align: center;
}
