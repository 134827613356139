.heading-h1{
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.no-regular-meetings{
  font-size: 2.5rem;
  text-align: center;
  text-wrap: wrap;
  white-space: normal;
  margin-top: 5rem;
}


#new-btn-cnt{
  display: flex;
  width: 100%;
  max-width: 600px;
}

#new-btn-cnt button{
  margin: 1rem 2rem 0 auto;
  font-size: 2.5rem;
  padding: 1.2rem 2rem;
  border-radius: 1rem;
  border: none;
  color: white;
  background-color: var(--blue-btn-color);
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: all .3s ease-in-out;
  
}
#new-btn-cnt button:hover{
  background-color: var(--blue-btn-color-hover);
}
#new-btn-cnt button:active{
  box-shadow: none;
  transform: translate(1.5px, 1.5px);
  background-color: var(--blue-btn-color);
}
