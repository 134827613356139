.main{
  flex-grow: 1;
}
.content{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

/* voting */
.overlay{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .2);

  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

/* /voting */


