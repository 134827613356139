.router{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

