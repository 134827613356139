.wqSection {
  display: flex;
  flex-direction: column;
  gap: 1rem
}
.backlogHeading{
  display: flex;
  justify-content: center;
  font-size: 3rem;
  align-items: center;
}
