.content{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.content > div{
  margin: 0 2rem;
}
.diagramWrapper{
  max-width: 500px;
  width: 100%;
}
.diagramHeading{
  
}
.diagramContainer{
  width: 100%;
}
.bulletpointsContainer{
  width: 300px;
}
.bpHeading{
  margin-bottom: 0;
}
.bpHeading2{
  font-size: 1.35rem;
  margin-top: 5px;
  color: grey;
}
.bpList > li::marker{
  content: '> ';
  font-size: 1.7rem;
}
.bpList{
  padding: 0;
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.bp{
  text-align:left;
}
.continueButtonWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}
.continueButton{
  background-color: var(--btn-color) !important;
  color: white !important;
  font-size: 1.6rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.continueButton:hover{
  background-color: var(--btn-color-hover) !important;
}
