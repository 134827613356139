.slider{
  width: 100%;
  height: 7px;
  border-radius: 6px;
  background-color: darkgray;
  display: flex;
  align-items: flex-end;
}
.circleGroup{
  width: 20px;
  height: 20px;
  position: absolute;
}
.circle{
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  border-radius: 20px;
  position: absolute;
}
@media print{
  .slider, .circle{
    border: 1px solid black;
  }
}
