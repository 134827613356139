#main{
  width: 100%;
  display: flex;
  flex-direction: column;
  
  height: 90vh;
}

.info-p {
  font-family: 'Figtree';
  width: 70%;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  text-align: center; 
  justify-self: center;
  align-self: center;
}

.info-p.contrast, .info-p.dark {
  color: white;
}
