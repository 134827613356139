.circle{
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  border-radius: 20px;
  position: absolute;
}
