.content{
  width: 100%;
  max-width: 800px;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 4rem;
}

.heading{
 margin: 3rem auto;
 font-size: 2.5rem;
}
.heading1{
  font-size: 3rem;
  margin: 0;
  margin-bottom: 1rem;
}
.heading2{
  font-size: 2.5rem;
  margin: 0;
  margin-top: 3rem;
}
/* share link */

.shareLinkWrapper{
  display: flex;

  background-color: lightgray;
  padding: 1.5rem;
  border-radius: 1rem;

  font-size: 2rem;
}
.shareLinkContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.shareLinkContainer > span{
  font-size: min(2.2rem, max(5vw, 2rem));
  text-align: center;
  text-wrap: wrap;
}
.shareLinkContainer > a{
  font-size: min(1.4rem, max(4vw, 1rem));
  overflow-x: auto;
}
.shareLinkButtonWrapper{
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

/* /share link */
.newEmailWrapper{
  display: flex;
  width: 80%;
  min-width: 300px;
  height: 56px;
  flex-wrap: wrap;
}
.newEmailInput{
  min-width: 225px;
}
.newEmailButton{
  margin: 1rem 1rem 0 auto !important;
  font-size: 1.2rem !important;
  height: min-content !important;
}
.newEmailInput{
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 2rem;
  margin: 0 2rem !important;
}

.emailsList{
  width: 100%;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 400px;
  overflow-y: auto;
}
.emailItem{
  display: flex;
  width: 100%;
}
.emailContainer{
  flex-grow: 1;
  border-radius: 2rem;
  margin: 0 2rem;
  padding: .5rem 2rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emailText{
  font-size: 2rem;
  max-width: 80%;
  overflow: hidden;
}
.emailStatus{
  font-size: 1.5rem;
  margin-left: 1rem;
}
.deleteEmailButton{
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 2rem;

  transition: scale .2s ease;
}
.deleteEmailButton:hover{
  scale: 1.1;
}
.deleteEmailButton:active{
  scale: .9;
}
.sendInviteButtonWrapper{
}
.sendInviteButton{
  font-size: 1.5rem !important;
}

