.endWarningOverlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  display: grid;
  place-items: center;

  background-color: rgba(0, 0, 0, 0.4);
}
.endWarningContainer{
  position: relative;
  background-color: white;

  max-width: calc(100vw - 4rem);

  padding: 2rem;
  
  border-radius: 1rem;
  box-shadow: 0 0 8rem rgba(255, 255, 255, .5);
}
.endWarningHeading1{
  margin: 0;
  font-size: 3rem;
}
.endWarningHeading2{
  max-width: 500px;
}
.endWarningButtons{
  display: flex;
  justify-content: space-evenly;
}
.endWarningButton{
  border: none;
  font-size: 2rem;
  padding: .5rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: transform .2s ease, box-shadow .2s ease;
}
.endWarningButton:hover{
  transform: translateY(-2px);
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.4);
}
.endWarningButton:active{
  transform: translateY(4px);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
}
.endProceed{
  background-color: var(--blue-btn-color);
  color: white;
}
