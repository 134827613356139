.h1{
  margin: 2rem 0 0;
  font-size: 2.7rem;
}
.h2{
  font-size: 1.7rem;
}
.content{
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items:normal;
  justify-content: normal;
}
.basicStats{
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: 1rem auto;
  width: 100%;
}
.basicStats > div{
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(to right, rgb(1, 112, 160), rgba(172, 224, 246, 0.50) 210%);
  color: white;
}
.basicStats > div > span{
  font-size: 2rem;
  margin: .5rem auto;
}
.toggleResultsButtonContainer{
  display: flex;
  justify-content: flex-end;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.resultsContainer > ul > li > p {
  margin-left: auto;
  margin-right: auto;
}
.noResults{
  margin: 2rem auto;
  font-size: 2rem;
  text-align: center;
}
.printButton, .saveButton{
  font-size: 2rem;
}
.saveButton{
  margin-right: .5rem;
}
.printButton{
  margin-right: auto;
}
